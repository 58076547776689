import React, { ReactElement } from 'react';
import { useHistory, useParams } from 'react-router';
import {
  AppTextField,
  AppSelect,
  IMultipleSelectProps,
  AppCommon,
  IAdvancedSelectProps,
  AppAdvancedselect,
} from 'components/molecules/FormControls';
import {
  Control,
  Controller,
  useWatch,
  useForm,
  useFormContext,
} from 'react-hook-form';
import _ from 'lodash';
import Button, { ButtonProps } from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import useStyles from '../styles';
import TranslatedText from 'components/atoms/TranslatedText';
import { useDispatch } from 'react-redux';
import { AuthRoles, Gender } from 'constants/index';
import GridLayout, { IElement } from 'components/molecules/GridLayout';
import { colors, IconButton, Paper, useTheme } from '@material-ui/core';
import RoutineDetailsActions from '../../../store/actions';
import * as RoutineDetailsSelectors from '../../../store/selectors';
import { StateStatus } from 'redux/utils/common';
import { NoNullFields, OverrideValues } from 'utils/generics';
import NavigationConfig from 'config/navigationConfig';
import {
  IFormData,
  VectorIconsList,
  defaultValues,
} from '../../../configuration';
import {
  closeUploadedFilesModal,
  openUploadedFilesModal,
} from 'components/compounds/UploadedFilesModal';
import CloudUploadOutlinedIcon from '@material-ui/icons/CloudUploadOutlined';
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import {
  getFileDetails,
  openFileToNewTab,
  openFileToNewTabAsync,
} from 'services/api/file';
import HighlightOffOutlinedIcon from '@material-ui/icons/HighlightOffOutlined';
import VideocamOutlinedIcon from '@material-ui/icons/VideocamOutlined';
import { FileType } from 'constants/filesConst';
import Environment from 'config/environmentConfig';
import ImageWrapper from 'components/app_common/FormFileWrapper';

export const ImageUrlSection = () => {
  const { getValues, errors, control, setValue } = useFormContext<IFormData>();
  const imageId = useWatch({
    control,
    name: 'imageId',
  });

  return (
    <AppCommon.Section
      // contentIsVisible={params.mode === 'edit'}
      title="Image URL"
    >
      <div>
        <AppTextField
          variant="outlined"
          size="medium"
          margin="normal"
          error={'imageId' in errors}
          helperText={errors.imageId && errors.imageId.message}
          rules={{}}
          fullWidth
          id="imageId"
          disabled
          label={<TranslatedText defaultText={'Image Url'} />}
          control={control}
          autoComplete="imageId"
          icon={{
            ComponentRight: (
              <>
                <IconButton
                  onClick={async () => {
                    let initialValue = getValues().title ?? undefined;
                    if (getValues().imageId) {
                      try {
                        const fileDetails = await getFileDetails(
                          getValues().imageId as string
                        );
                        initialValue = fileDetails.data.name;
                      } catch (error) {}
                    }

                    openUploadedFilesModal({
                      handleSelectedFile: (filePath) => {
                        setValue('imageId', filePath);
                        closeUploadedFilesModal();
                      },
                      selectItem: true,
                      initialSearch: initialValue,
                      type: FileType.Image,
                    });
                  }}
                >
                  <CloudUploadOutlinedIcon />
                </IconButton>
                <IconButton
                  onClick={() => {
                    setValue('imageId', null);
                  }}
                >
                  <HighlightOffOutlinedIcon />
                </IconButton>
              </>
            ),
            ComponentLeft: (
              <IconButton
                onClick={async () => {
                  getValues().imageId &&
                    (await openFileToNewTabAsync(getValues().imageId ?? ''));
                }}
              >
                <VisibilityOutlinedIcon />
              </IconButton>
            ),
          }}
          name="imageId"
        />
        <ImageWrapper type="img" fieldId="imageId" />
      </div>
    </AppCommon.Section>
  );
};

// const ImageContainer = React.memo(() => {
//   const { getValues, errors, control, setValue } = useFormContext<IFormData>();
//   const imageId = useWatch({
//     control,
//     name: 'imageId',
//   }) as string | null;
//   const [imageUrl, setImageUrl] = React.useState<string>();

//   React.useEffect(() => {
//     (async () => {
//       if (imageId) {
//         const details = await getFileDetails(imageId);
//         setImageUrl(`${Environment.API_BASE_URL}${details.data.path}`);
//       }
//     })();
//   }, [imageId]);

//   return (
//     <div
//       style={{
//         display: 'flex',
//         justifyContent: 'center',
//         alignItems: 'center',
//         background: colors.grey['100'],
//         cursor: 'pointer',
//       }}
//       onClick={async () => {
//         getValues().imageId &&
//           (await openFileToNewTabAsync(getValues().imageId ?? ''));
//       }}
//     >
//       {imageUrl && (
//         <img
//           src={imageUrl}
//           style={{ width: 'auto', height: 'auto', maxHeight: 200 }}
//         />
//       )}
//     </div>
//   );
// });

export default React.memo(ImageUrlSection);
