import React, { ReactElement } from 'react';
import { useHistory, useParams } from 'react-router';
import {
  AppAdvancedselect,
  AppTextField,
  AppSelect,
  IAdvancedSelectProps,
  AppCommon,
} from 'components/molecules/FormControls';
import {
  Control,
  Controller,
  useForm,
  useFormContext,
  useWatch,
} from 'react-hook-form';
import _ from 'lodash';
import type { IFormData } from '../../types';
import GridLayout from 'components/molecules/GridLayout';
import { ItemGroups, ItemTypeEnum } from 'constants/typesConst';
import FormatLineSpacingIcon from '@material-ui/icons/FormatLineSpacing';
import * as ItemDetailsSelectors from '../../store/selectors';
import { IconButton, Paper } from '@material-ui/core';
import { magicFormat } from 'utils/chunks';
import PrettyPrintJson from 'components/atoms/PrettyPrintJson';

export type IBookContentSectionProps = {};

const VideoTranscriptSection = () => {
  const itemDetails = ItemDetailsSelectors.useTrickDetails();

  return (
    <AppCommon.Section title={'Video Transcript'}>
      <Paper
        style={{ overflow: 'auto', maxHeight: '35vh', marginTop: '0.8rem' }}
        variant={'outlined'}
      >
        <PrettyPrintJson
          style={{ padding: '0.5rem' }}
          data={(itemDetails.data?.video_Transcript ?? {}) as never}
        />
      </Paper>
    </AppCommon.Section>
  );
};

export default React.memo(VideoTranscriptSection);
