import React from 'react';
import BasicTemplate from '../../BasicTemplate';
import {
  AnswerSplitItem,
  IItemDetailsProps,
  BlankSplitItem,
  getDefaultValues,
} from '../../../configuration';

const WordItemForm = (props: IItemDetailsProps) => {
  return (
    <BasicTemplate
      {...props}
      config={{
        sectionTitle: {
          visibility: {
            includeTitle: true,
          },
        },
        sectionExamples: {
          visibility: undefined,
        },
        senctionCategories: {
          visibility: { includeCategories: true },
        },
        senctionBasicInfo: {
          visibility: {
            includeStatus: true,
            includePublic: true,
            includeType: true,
            includeDifficulty: true,
            includeNamespaces: true,
          },
        },
        senctionVideo: {
          visibility: {},
          required: true,
        },
        senctionImage:
          props.mode === 'add'
            ? undefined
            : {
                visibility: {},
              },
        senctionExplanation:
          props.mode === 'add'
            ? undefined
            : {
                visibility: {
                  includeDescription: true,
                },
                renameLabel: 'Notes',
              },
        senctionVideoTranscript:
          props.mode === 'add'
            ? undefined
            : {
                visibility: {},
              },
      }}
    />
  );
};

export default WordItemForm;
