import React, { ReactElement } from 'react';
import { useHistory, useParams } from 'react-router';
import {
  AppTextField,
  AppSelect,
  IMultipleSelectProps,
  AppCommon,
  IAdvancedSelectProps,
  AppAdvancedselect,
} from 'components/molecules/FormControls';
import {
  Control,
  Controller,
  useWatch,
  useForm,
  useFormContext,
} from 'react-hook-form';
import Button, { ButtonProps } from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import useStyles from '../styles';
import TranslatedText from 'components/atoms/TranslatedText';
import { useDispatch } from 'react-redux';
import { AuthRoles, Gender } from 'constants/index';
import GridLayout, { IElement } from 'components/molecules/GridLayout';
import {
  colors,
  IconButton,
  Paper,
  TextField,
  useTheme,
} from '@material-ui/core';
import RoutineDetailsActions from '../../../store/actions';
import * as RoutineDetailsSelectors from '../../../store/selectors';
import { StateStatus } from 'redux/utils/common';
import { NoNullFields, OverrideValues } from 'utils/generics';
import { CollectionTypeOptions } from 'constants/dropdownOptions';
import {
  IFormData,
  VectorIconsList,
  defaultValues,
} from '../../../configuration';
import {
  closeUploadedFilesModal,
  openUploadedFilesModal,
} from 'components/compounds/UploadedFilesModal';
import CloudUploadOutlinedIcon from '@material-ui/icons/CloudUploadOutlined';
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import LanguageIcon from '@material-ui/icons/Language';
import { extractUniqueNames, removeTagsFromString } from 'utils/strings';
import _ from 'lodash';
import {
  openTranslationModalFormModal,
  ITranslationsModalFormParams,
  SelectedLanguages,
} from 'components/app_common/TranslationsModal';
import { useContextProps } from '../../../provider';

interface ITypeSectionProps {
  handleTranslation: (
    name: string,
    title: string,
    options?: Partial<ITranslationsModalFormParams>
  ) => void;
}

export const TypeSection = ({ handleTranslation }: ITypeSectionProps) => {
  const { getValues, errors, control, setValue } = useFormContext<IFormData>();
  const collectionType = useWatch({
    control,
    name: 'type',
  });
  const props = useContextProps();

  return (
    <AppCommon.Section
      footer={<SelectedLanguages fieldName="typeAlias" control={control} />}
      iconLeft={
        <IconButton
          onClick={() => {
            handleTranslation('typeAlias', 'Type Alias');
          }}
          size="small"
          style={{ marginRight: '0.3rem' }}
        >
          <LanguageIcon />
        </IconButton>
      }
      style={{
        display:
          collectionType === null || Boolean(props.collectionId)
            ? 'block'
            : 'none',
      }}
      title="Type"
    >
      <div>
        <GridLayout
          justify="flex-start"
          elements={[
            {
              id: 'type',
              element: (
                <AppSelect
                  variant="outlined"
                  size="medium"
                  margin="normal"
                  error={'type' in errors}
                  helperText={errors.type && errors.type.message}
                  rules={{ required: <AppCommon.RequiredField /> }}
                  required
                  fullWidth
                  id="type"
                  label={<TranslatedText defaultText={'Type'} />}
                  control={control}
                  autoComplete="type"
                  name="type"
                  options={CollectionTypeOptions}
                />
              ),
              size: 3,
            },
            {
              id: 'typeAlias',
              element: (
                <AppTextField
                  variant="outlined"
                  size="medium"
                  margin="normal"
                  error={'typeAlias' in errors}
                  helperText={errors.type && errors.type.message}
                  rules={{}}
                  fullWidth
                  id="typeAlias"
                  label={<TranslatedText defaultText={'Alias'} />}
                  control={control}
                  autoComplete="typeAlias"
                  name="typeAlias"
                  placeholder={(collectionType as string) ?? 'Enter alias..'}
                />
              ),
              size: 9,
            },
          ]}
        />
      </div>
    </AppCommon.Section>
  );
};

export default React.memo(TypeSection);
