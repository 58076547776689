import React, { ReactElement } from 'react';
import { useHistory, useParams } from 'react-router';
import {
  AppTextField,
  AppSelect,
  IMultipleSelectProps,
  AppCommon,
  IAdvancedSelectProps,
  AppAdvancedselect,
} from 'components/molecules/FormControls';
import {
  Control,
  Controller,
  useWatch,
  useForm,
  useFormContext,
} from 'react-hook-form';
import Button, { ButtonProps } from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import useStyles from '../styles';
import TranslatedText from 'components/atoms/TranslatedText';
import { useDispatch } from 'react-redux';
import { AuthRoles, Gender } from 'constants/index';
import GridLayout, { IElement } from 'components/molecules/GridLayout';
import {
  colors,
  IconButton,
  Paper,
  TextField,
  useTheme,
} from '@material-ui/core';
import RoutineDetailsActions from '../../../store/actions';
import * as RoutineDetailsSelectors from '../../../store/selectors';
import { StateStatus } from 'redux/utils/common';
import { NoNullFields, OverrideValues } from 'utils/generics';
import NavigationConfig from 'config/navigationConfig';
import {
  IFormData,
  VectorIconsList,
  defaultValues,
} from '../../../configuration';
import {
  closeUploadedFilesModal,
  openUploadedFilesModal,
} from 'components/compounds/UploadedFilesModal';
import CloudUploadOutlinedIcon from '@material-ui/icons/CloudUploadOutlined';
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import {
  getFileDetails,
  openFileToNewTab,
  openFileToNewTabAsync,
} from 'services/api/file';
import HighlightOffOutlinedIcon from '@material-ui/icons/HighlightOffOutlined';
import VideocamOutlinedIcon from '@material-ui/icons/VideocamOutlined';
import { FileType } from 'constants/filesConst';
import Environment from 'config/environmentConfig';
import FormImageWrapper from 'components/app_common/FormFileWrapper';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { extractUniqueNames, removeTagsFromString } from 'utils/strings';
import _ from 'lodash';
import {
  openTextToAudioModal,
  closeTextToAudioModal,
} from 'components/app_common/TextToAudioModal';

export const AudioUrlSection = () => {
  const { getValues, errors, control, setValue } = useFormContext<IFormData>();
  // const audioId = useWatch({
  //   control,
  //   name: 'audioId',
  // });

  return (
    <AppCommon.Section
      // contentIsVisible={params.mode === 'edit'}
      title="Audio URL"
    >
      <div>
        <AppTextField
          variant="outlined"
          size="medium"
          margin="normal"
          error={'audioId' in errors}
          helperText={errors.audioId && errors.audioId.message}
          rules={{}}
          fullWidth
          id="audioId"
          disabled
          label={<TranslatedText defaultText={'Audio Url'} />}
          control={control}
          autoComplete="audioId"
          icon={{
            ComponentRight: (
              <>
                <IconButton
                  onClick={async () => {
                    let initialValue = getValues().title ?? undefined;
                    if (getValues().audioId) {
                      try {
                        const fileDetails = await getFileDetails(
                          getValues().audioId as string
                        );
                        initialValue = fileDetails.data.name;
                      } catch (error) {}
                    }

                    openUploadedFilesModal({
                      handleSelectedFile: (filePath) => {
                        setValue('audioId', filePath);
                        closeUploadedFilesModal();
                      },
                      selectItem: true,
                      initialSearch: initialValue,
                      type: FileType.Audio,
                    });
                  }}
                >
                  <CloudUploadOutlinedIcon />
                </IconButton>
                <IconButton
                  onClick={() => {
                    setValue('audioId', null);
                  }}
                >
                  <HighlightOffOutlinedIcon />
                </IconButton>
              </>
            ),
            ComponentLeft: (
              <IconButton
                onClick={async () => {
                  getValues().audioId &&
                    (await openFileToNewTabAsync(getValues().audioId ?? ''));
                }}
              >
                <VisibilityOutlinedIcon />
              </IconButton>
            ),
          }}
          name="audioId"
        />
        <FormImageWrapper type="audio" fieldId="audioId" />
        <AudioTextDialog />
      </div>
    </AppCommon.Section>
  );
};

interface IAudioTextDialogProps {}

function AudioTextDialog({}: IAudioTextDialogProps) {
  // const [open, setOpen] = React.useState(false);
  const { getValues, errors, control, setValue } = useFormContext<IFormData>();
  const watchDescription = useWatch({
    control,
    name: 'description',
  }) as string;

  const handleClickOpen = () => {
    const text = `${removeTagsFromString(
      removeTagsFromString(watchDescription?.toString() ?? '')
    )}`;
    openTextToAudioModal({
      defaultValues: {
        text,
        speakers: extractUniqueNames(text).map((el) => ({
          name: el,
          gender: 'male',
        })),
      },
      dialogue: true,
      onSuccess: (data) => {
        data.id && setValue('audioId', data.id);
        // closeTextToAudioModal();
      },
      title: getValues().title ?? '',
    });
  };

  return (
    <div>
      <Button
        onClick={handleClickOpen}
        variant="contained"
        style={{ marginTop: '1rem' }}
        color="secondary"
      >
        {'Generate audio'}
      </Button>
    </div>
  );
}

export default React.memo(AudioUrlSection);
