import React from 'react';
import BasicTemplate from '../../BasicTemplate';
import { CollectionDetailsProps } from '../../../types';
import { useContextProps } from '../../../provider';

const BookForm = () => {
  const props = useContextProps();

  return (
    <BasicTemplate
      config={{
        sectionTitle: {
          visibility: {
            includeTitle: true,
          },
        },
        senctionBasicInfo: {
          visibility: {
            includeNamespaces: true,
            includePublic: true,
            includeStatus: true,
            // includeType: true,
            includeDifficulty: true,
            includeIsTemplate: true,
          },
        },
        senctionType: {
          visibility: {
            includeType: true,
          },
        },
        senctionImage: {
          visibility: {
            includeImage: true,
          },
        },
        senctionAudio: {
          visibility: {
            includeAudio: true,
          },
        },
        senctionTags: {
          visibility: {
            includeTags: true,
          },
        },
        senctionDescription: {
          visibility: {
            includeDescription: true,
          },
        },
        sectionCollectionItems: {
          visibility: {
            includeItemsList: true,
            includeUploadPdfButton: props.mode === 'add' ? false : true,
            includeSelectItemsButton: props.mode === 'add' ? false : true,
            includeCreateItemButton: props.mode === 'add' ? false : true,
          },
        },
        export: {
          book: true,
          description: true,
        },
      }}
    />
  );
};

export default BookForm;
