import React, { ReactElement } from 'react';
import { useHistory, useParams } from 'react-router';
import {
  AppTextField,
  AppSelect,
  IMultipleSelectProps,
  AppCommon,
  IAdvancedSelectProps,
  AppAdvancedselect,
} from 'components/molecules/FormControls';
import {
  Control,
  Controller,
  useWatch,
  useForm,
  useFormContext,
} from 'react-hook-form';
import Button, { ButtonProps } from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import useStyles from '../styles';
import TranslatedText from 'components/atoms/TranslatedText';
import { useDispatch } from 'react-redux';
import { AuthRoles, Gender } from 'constants/index';
import GridLayout, { IElement } from 'components/molecules/GridLayout';
import {
  colors,
  IconButton,
  Paper,
  TextField,
  useTheme,
} from '@material-ui/core';
import RoutineDetailsActions from '../../../store/actions';
import * as RoutineDetailsSelectors from '../../../store/selectors';
import { StateStatus } from 'redux/utils/common';
import { NoNullFields, OverrideValues } from 'utils/generics';
import { CollectionTypeOptions } from 'constants/dropdownOptions';
import {
  IFormData,
  VectorIconsList,
  defaultValues,
} from '../../../configuration';
import {
  closeUploadedFilesModal,
  openUploadedFilesModal,
} from 'components/compounds/UploadedFilesModal';
import CloudUploadOutlinedIcon from '@material-ui/icons/CloudUploadOutlined';
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import LanguageIcon from '@material-ui/icons/Language';
import { extractUniqueNames, removeTagsFromString } from 'utils/strings';
import _ from 'lodash';
import {
  CollectionTypeEnum,
  CollectionTypeNeedsQuizType,
  ItemGroups,
  ItemTypeEnum,
  QuizTypeEnum,
  QuizVariantEnum,
} from 'constants/typesConst';
import { useContextProps } from '../../../provider';
import QuizTypesButton from '../QuizTypesButton';

export const QuizSection = () => {
  const { getValues, errors, control, setValue } = useFormContext<IFormData>();
  const collectionType = useWatch({
    control,
    name: 'type',
  });
  const props = useContextProps();

  return (
    <AppCommon.Section
      style={{
        display:
          collectionType === null || Boolean(props.collectionId)
            ? 'block'
            : 'none',
      }}
      title="Quiz"
    >
      <div>
        <GridLayout
          justify="flex-start"
          elements={[
            {
              id: 'quizVariant',
              element: (
                <AppSelect
                  variant="outlined"
                  size="medium"
                  margin="normal"
                  error={'quizVariant' in errors}
                  helperText={errors.quizVariant && errors.quizVariant.message}
                  rules={{ required: <AppCommon.RequiredField /> }}
                  required
                  fullWidth
                  id="quizVariant"
                  label={<TranslatedText defaultText={'Variant'} />}
                  control={control}
                  autoComplete="quizVariant"
                  name="quizVariant"
                  options={options}
                />
              ),
              size: 3,
            },
            {
              id: 'type',
              element: (
                <QuizTypesWrapper
                  id="quizTypes"
                  control={control}
                  placeholder="Quiz Types"
                  name="quizTypes"
                  rules={{}}
                  margin="normal"
                  isMulti
                  options={QuizTypes}
                  helperText={
                    errors.quizTypes && (errors.quizTypes as any).message
                  }
                />
              ),
              size: 9,
            },
          ]}
        />
      </div>
    </AppCommon.Section>
  );
};

const QuizTypes = Object.values(QuizTypeEnum).map((el) => ({
  id: el,
  label: el,
}));

const QuizTypesWrapper = (props: Omit<IAdvancedSelectProps, ''>) => {
  const watchType = useWatch({
    control: props.control,
    name: 'type',
  }) as CollectionTypeEnum;
  return (
    <>
      {Object.values(CollectionTypeNeedsQuizType).includes(watchType) && (
        <Box display="flex">
          <div style={{ flex: 1, marginRight: '0.5rem' }}>
            <AppAdvancedselect {...props} />
          </div>
          <QuizTypesButton />
        </Box>
      )}
    </>
  );
};

const options = Object.values(QuizVariantEnum).map((el) => ({
  label: el,
  id: el,
}));

export default React.memo(QuizSection);
