import React from 'react';
import {
  AnswerSplitItem,
  IItemDetailsProps,
  BlankSplitItem,
  getDefaultValues,
} from '../../configuration';
import { ItemTypeEnum } from 'constants/typesConst';
import WordItemForm from '../templates/WordItemForm';
import { useFormContext, useWatch } from 'react-hook-form';
import { IFormData } from '../../types';
import DefaultItemForm from '../templates/DefaultItemForm';
import PhraseItemForm from '../templates/PhraseItemForm';
import VideoItemForm from '../templates/VideoItemForm';
import TrueFalseForm from '../templates/TrueFalseForm';
import MultipleChoiceItemForm from '../templates/MultipleChoiceItemForm';
import QuestionAnswerForm from '../templates/QuestionAnswerForm';
import BookSenctionItemForm from '../templates/BookSenctionItemForm';
import FillTheBlankMultipleForm from '../templates/FillTheBlankMultipleForm';
import TextFragmentItemForm from '../templates/TextFragmentItemForm';
import QuoteItemForm from '../templates/QuoteItemForm';

const ItemSelector: {
  [key in ItemTypeEnum]: React.FC<IItemDetailsProps>;
} = {
  [ItemTypeEnum.FillTheGapMultiple]: FillTheBlankMultipleForm,
  [ItemTypeEnum.FillTheGapMultipleSpelling]: FillTheBlankMultipleForm, // DefaultItemForm,
  [ItemTypeEnum.MultipleChoice]: MultipleChoiceItemForm,
  [ItemTypeEnum.Phrase]: PhraseItemForm,
  [ItemTypeEnum.Sentence]: PhraseItemForm,
  [ItemTypeEnum.Quote]: QuoteItemForm,
  [ItemTypeEnum.Saying]: PhraseItemForm,
  [ItemTypeEnum.Idiom]: PhraseItemForm,
  [ItemTypeEnum.QuestionAnswer]: QuestionAnswerForm,
  [ItemTypeEnum.Rearrangement]: DefaultItemForm,
  [ItemTypeEnum.RearrangementAnswer]: DefaultItemForm,
  [ItemTypeEnum.RearrangementSpelling]: DefaultItemForm,
  [ItemTypeEnum.Text]: TextFragmentItemForm,
  [ItemTypeEnum.TrueOrFalse]: TrueFalseForm,
  [ItemTypeEnum.Word]: WordItemForm,
  [ItemTypeEnum.GenericItem]: DefaultItemForm,
  [ItemTypeEnum.Video]: VideoItemForm,
  [ItemTypeEnum.BookSection]: BookSenctionItemForm,
};

const TemplateSelector = (props: IItemDetailsProps) => {
  const Node = ItemSelector[props.type as ItemTypeEnum];

  return <Node {...props} />;
};

export default TemplateSelector;
